<template>
  <div id="app">
    <navTop></navTop>
    <div
      class="main"
      @scroll="handleScroll"
      :style="{ height: height }"
      ref="main"
    >
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
export default {
  name: "App",
  data() {
    return {
      height: "",
    };
  },
  components: {
    navTop: () => import("@/layouts/navTop.vue"),
  },
  computed: {
    ...mapState(["homeScrollTop"]),
  },
  watch: {
    $route(val) {
      if (val.path) {
        this.$refs.main.scrollTop = 0;
        this.setHomeScrollTop(0);
      }
    },
  },
  mounted() {
    this.height = window.innerHeight + "px";
  },
  methods: {
    ...mapMutations(["setHomeScrollTop"]),
    handleScroll() {
      let scrollTop = this.$refs.main.scrollTop;
      if (
        (scrollTop > 64 && this.homeScrollTop < 64) ||
        (scrollTop < 64 && this.homeScrollTop > 64)
      ) {
        this.setHomeScrollTop(scrollTop);
      }
    },
  },
};
</script>

<style lang="scss">
.main {
  overflow-y: scroll;
  margin-top: -64px;
}
</style>
