import Vue from 'vue'
import App from './App.vue'
import router from './router'
import 'element-ui/lib/theme-chalk/index.css';
import '@/assets/styles/reset.css'
import '@/assets/styles/index.scss'
import {
  Carousel,
  CarouselItem,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  Tooltip
} from 'element-ui';
import store from "./store"

Vue.use(Carousel)
  .use(CarouselItem)
  .use(Dropdown)
  .use(DropdownMenu)
  .use(DropdownItem)
  .use(Tooltip)
Vue.config.productionTip = false


new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')