import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
};

const routes = [{
        path: "/",
        component: () => import("@/views/home/index.vue"),
        meta: {
            title: "首页"
        }
    },
    {
        path: "/diagnosis",
        component: () => import("@/views/diagnosis/index.vue"),
        meta: {
            title: "AI智能辅助诊断"
        }
    },
    {
        path: "/quality",
        component: () => import("@/views/quality/index.vue"),
        meta: {
            title: "AI智能病历质控"
        }
    },
    {
        path: "/aboutUs",
        component: () => import("@/views/aboutUs/index.vue"),
        meta: {
            title: "关于我们"
        }
    },
    {
        path: "/stories",
        component: () => import("@/views/stories/index.vue"),
        meta: {
            title: "精选案例"
        }
    }
]

const router = new VueRouter({
    routes
})

router.beforeEach((to, form, next) => {
    document.title = `${to.meta.title} | 如玥星辰`
    next()
})

export default router