import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex)

const store = new Vuex.Store({
    state: {
        homeScrollTop: 0
    },
    getters: {
        homeScrollTop: state => {
            return state.homeScrollTop
        }
    },
    mutations: {
        setHomeScrollTop(state, payload) {
            state.homeScrollTop = payload
        }
    },
    actions: {

    }
})

export default store